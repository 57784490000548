import { parseJwt } from '../utils/utils';

export default ($api, base) => ({
  accountUrl() {
    return `${base.accountServiceUrl()}/auth`;
  },
  userUrl() {
    return `${base.accountServiceUrl()}/user`;
  },
  adhocUrl(tenant) {
    return `${base.websocketServiceUrl(tenant)}/adhoc`;
  },
  guestUrl() {
    return `${base.accountServiceUrl()}/guest`;
  },
  organizerUrl() {
    return `${base.accountServiceUrl()}/organizer`;
  },
  currencyUrl() {
    return `${base.accountServiceUrl()}/currency`;
  },
  feeUrl() {
    return `${base.accountServiceUrl()}/fee`;
  },
  certificateUrl() {
    return `${base.accountServiceUrl()}/certificate`;
  },
  marketControlUrl() {
    return `${base.accountServiceUrl()}/market-control`;
  },

  async loginThirdParty(token, thirdParty) {
    return await $api.$post(`${this.accountUrl()}/external`, {
      token,
      thirdParty
    });
  },
  async loginGuest() {
    return (await $api.$post(`${this.guestUrl()}/login`)).data;
  },
  async resetPassword(data) {
    return (await $api.$post(`${this.userUrl()}/password/reset`, data)).data;
  },
  async register(data) {
    return await $api.$post(`${this.userUrl()}/`, data);
  },
  async deleteAccount(userId) {
    return await $api.delete(`${this.userUrl()}/${userId}`);
  },
  async confirmEmail(code) {
    return await $api.get(`${this.userUrl()}/verify/${code}`);
  },
  async changePassword({ code, password }) {
    return (
      await $api.$post(`${this.userUrl()}/password/${code}`, { password })
    ).data;
  },
  async identifyWallet(secret, tenant) {
    return (
      await $api.$post(`${this.adhocUrl(tenant)}`, {
        secret
      })
    ).data;
  },
  async getUser(id) {
    return (await $api.$get(`${this.userUrl()}/${id}`)).data;
  },
  async saveUser(id, data) {
    return (await $api.$patch(`${this.userUrl()}/${id}`, data)).data;
  },
  async sendEmailVerification() {
    return await $api.$post(`${this.userUrl()}/verify/resend`);
  },
  async sendPhoneVerification() {
    return await $api.$post(`${this.userUrl()}/verify/phone/request`);
  },
  async verifyPhoneCode(code, userData) {
    const data = userData
      ? await $api.$post(`${this.userUrl()}/verify/phone/${code}`, userData)
      : await $api.$post(`${this.userUrl()}/verify/phone/${code}`);
    return data;
  },
  async updateToken($auth) {
    if (!$auth.state.user.impersonated_by) {
      await $auth.refreshTokens();
    }
    const token = $auth.strategy.token.get();
    const tokenInfo = parseJwt(token);

    $api.setHeader('Authorization', token);
    const user = await this.getUser(tokenInfo.id);
    await $auth.setUser({
      ...user,
      ...tokenInfo
    });
    return user;
  },
  async updateTokenImpersonate($auth, token, store) {
    const tokenInfo = parseJwt(token);
    const user = await this.getUser(tokenInfo.id);
    // Handle cases where the user is already impersonated
    // and we just need to refresh the token
    // Otherwise, the impersonate request is called with the impersonated users' token
    if (!localStorage.getItem('impersonated_by_token')) {
      const currentToken = $auth.strategy.token.get();
      localStorage.setItem('impersonated_by_token', currentToken);
      localStorage.setItem(
        'impersonated_by_refresh_token',
        $auth.strategy.refreshToken.get()
      );
    }
    $auth.setUser({
      ...user,
      ...tokenInfo
    });
    $api.setHeader('Authorization', `Bearer ${token}`);

    $auth.strategy.token.set(token);

    return user;
  },
  async stopImpersonate($auth) {
    const token = localStorage.getItem('impersonated_by_token');
    const refreshToken = localStorage.getItem('impersonated_by_refresh_token');
    localStorage.removeItem('impersonated_by_token');
    localStorage.removeItem('impersonated_by_refresh_token');
    localStorage.setItem('auth._refresh_token.local', refreshToken);
    const tokenInfo = parseJwt(token);
    $api.setHeader('Authorization', `${token}`);
    const user = await this.getUser(tokenInfo.id);
    await $auth.setUser({
      ...user,
      ...tokenInfo
    });
    await $auth.strategy.token.set(token);

    return user;
  },
  async inviteUser(user) {
    return (await $api.$post(`${this.userUrl()}/invite`, user)).data;
  },
  async editUserPermissions(id, data) {
    return (await $api.$patch(`${this.userUrl()}/${id}/permission`, data)).data;
  },
  async listUsersInOrganizer(organizer) {
    return (
      await $api.$get(
        `${this.organizerUrl()}/${organizer}/users?fields=permissions`
      )
    ).data;
  },
  async searchUser(query) {
    return (await $api.$get(`${this.userUrl()}?email=${query}`)).data;
  },
  async searchUserByEmail(email) {
    return (await $api.$get(`${this.userUrl()}/email/${email}`)).data || false;
  },
  async getCurrencies() {
    return (await $api.$get(`${this.currencyUrl()}`)).data;
  },
  async getFees(organizerId) {
    const response = await $api.$get(`${this.feeUrl()}`, {
      params: {
        organizerId,
        with: '[currency]'
      }
    });
    return response?.data.length > 0 ? response.data : null;
  },
  async setFees(fee) {
    if (fee.id) {
      return (await $api.$patch(`${this.feeUrl()}/${fee.id}`, fee)).data;
    }
    return (await $api.$post(`${this.feeUrl()}`, fee)).data;
  },
  async impersonate(payload) {
    return await $api.$post(`${this.accountUrl()}/impersonate`, payload, {
      headers: {
        Authorization: payload.token
      }
    });
  },
  async revokeCertificate(enrollmentId) {
    return await $api.post(`${this.certificateUrl()}/revoke`, {
      enrollmentId
    });
  },
  async getDashboardToken(dashboardId, organizerName, eventId = undefined) {
    const payload = {
      dashboard_id: dashboardId
    };
    if (eventId) {
      payload.event_id = parseInt(eventId);
    }

    return await $api.post(
      `${this.organizerUrl()}/${organizerName}/report/token`,
      payload
    );
  },
  async refreshToken($auth, organizerId) {
    const oldToken = localStorage.getItem('auth._refresh_token.local');
    const res = await $api.post(`${this.accountUrl()}/refresh`, {
      refresh_token: oldToken,
      organizerId
    });
    await $auth.setUserToken(
      'Bearer ' + res?.data?.data?.token,
      res?.data?.data?.refreshToken
    );
    const tokenInfo = parseJwt(res.data.data.token);
    const user = await this.getUser(tokenInfo.id);
    await $auth.setUser({
      ...user,
      ...tokenInfo
    });
    $api.setHeader('Authorization', 'Bearer ' + res?.data?.data?.token);
  }
});
