// TODO: Add proper types
export const state = () => ({
  searchTerm: '',
  currentEvent: null,
  organizerId: null
});

export const mutations = {
  setSearchTerm(state, payload) {
    state.searchTerm = payload;
  },
  setCurrentEvent(state, payload) {
    state.currentEvent = payload;
  },
  setOrganizerId(state, payload) {
    state.organizerId = payload;
  }
};

export const actions: any = {
  setCurrentEvent({ commit }, event) {
    commit('setCurrentEvent', event);
  }
};

export const getters = {
  getSearchTerm: (state) => {
    return state.searchTerm;
  },
  getCurrentEvent: (state) => {
    return state.currentEvent;
  },
  getOrganizerId: (state) => {
    return state.organizerId;
  }
};
