<template>
  <div>
    <div class="footer__wrapper" :style="`background-color: ${color}`">
      <footer class="footer">
        <div class="footer__logo">
          <a :href="$whitelabel.links.website" target="_blank">
            <img alt="Logo" :src="require('assets/img/logo_footer.svg')" />
          </a>
        </div>
        <div class="footer__links">
          <a :href="$whitelabel.links.legalNotice" target="_blank">{{
            $t('main.legalNotice')
          }}</a>
          <a :href="$whitelabel.links.termsOfService" target="_blank">{{
            $t('main.termsOfService')
          }}</a>
          <a :href="$whitelabel.links.dataPrivacy" target="_blank">{{
            $t('main.dataPrivacy')
          }}</a>
          <a :href="$whitelabel.links.FAQAndSupport" target="_blank">{{
            $t('main.FAQAndSupport')
          }}</a>
        </div>
      </footer>
    </div>
    <div class="footer__copyright">
      <p>
        {{ $t('main.copyright') }}
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'BFooter',
  props: {
    color: {
      type: String,
      default: 'primaryText'
    }
  }
};
</script>
<style lang="scss" scoped>
@import '~@/assets/_scss/_utils/variables.scss';
@import '~@/assets/_scss/_utils/mixins.scss';
.footer {
  display: grid;
  grid-template-columns: 280px auto;
  max-width: $content-width;
  width: 100%;
  margin: 0 auto;
  color: var(--v-textPrimary-lighten5);
  @include respond-below(sm) {
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    grid-gap: 12px;
    justify-items: center;
  }
  &__wrapper {
    padding: 14px 20px 12px 20px;
  }
  &__logo {
    display: grid;
    grid-template-columns: 40px auto;
    align-items: center;
    p {
      @include respond-below(sm) {
        display: none;
      }
      color: inherit;
    }
    img {
      width: 32px;
    }
  }
  &__links {
    text-align: right;
    @include respond-below(sm) {
      text-align: center;
    }
    a {
      font-size: 14px;
      display: inline-block;
      padding: 12px 6px;
      color: inherit;
      text-decoration: none;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
    }
  }
  &__copyright {
    text-align: center;
    padding: 12px 0;
    p {
      font-size: 12px;
    }
  }
}
</style>
