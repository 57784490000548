export default ($api, base) => ({
  eventsUrl() {
    return `${base.bamgregatorUrl()}/event`;
  },
  ticketsUrl() {
    return `${base.bamgregatorUrl()}/ticket`;
  },
  eventTenantUrl(tenant) {
    return `${base.eventServiceUrl(tenant)}/event`;
  },
  venueUrl() {
    return `${base.venueServiceUrl()}/venue`;
  },
  categoryUrl() {
    return `${base.bamgregatorUrl()}/category`;
  },
  eventCategoryUrl(tenant) {
    return `${base.eventServiceUrl(tenant)}/category`;
  },
  organizerUrl() {
    return `${base.accountServiceUrl()}/organizer`;
  },
  seatsUrl() {
    return `${base.accountServiceUrl()}/seats-workspace`;
  },
  organizerEventUrl(tenant) {
    return `${base.eventServiceUrl(tenant)}/organizer`;
  },
  ticketUrl(tenant) {
    return `${base.eventServiceUrl(tenant)}/ticket`;
  },
  ticketConfigUrl(tenant, organizer, eventId) {
    return `${base.eventServiceUrl(
      tenant
    )}/organizer/${organizer}/event/${eventId}/ticket_config`;
  },
  ticketFormatUrl(tenant) {
    return `${base.eventServiceUrl(tenant)}/ticket_format`;
  },
  ticketDiscountUrl(tenant, organizer, eventId) {
    return `${base.eventServiceUrl(
      tenant
    )}/organizer/${organizer}/event/${eventId}/ticket_discount`;
  },
  questionnaireUrl(tenant) {
    return `${base.eventServiceUrl(tenant)}/questionnaire`;
  },
  async getPrivateEvents(tenant, q) {
    const params = {
      public: false,
      q
    };
    return await $api
      .$get(`${this.eventTenantUrl(tenant)}`, {
        params
      })
      .then((res) => res.data || []);
  },
  async getVenue(id) {
    // set request params
    const params = {
      with: 'address.position'
    };
    // get venue
    return await $api
      .$get(`${this.venueUrl()}/${id}`, {
        headers: {
          'Cache-Control': 'no-cache'
        },
        params,
        cache: false
      })
      .then((res) => res.data);
  },
  async getEventSale(tenant, id, params) {
    const response = await $api.$get(`${this.eventTenantUrl(tenant)}/${id}`, {
      params: {
        ...params,
        public: true
      },
      headers: {
        'Cache-Control': 'no-cache'
      },
      cache: false
    });
    if (response?.data) {
      return response.data;
    } else {
      throw new Error('event not found');
    }
  },
  async getPrivateEvent(tenant, eventId, accessToken, params) {
    return (
      await $api.get(
        `${this.eventTenantUrl(tenant)}/${eventId}/private/${accessToken}`,
        {
          params,
          cache: {
            ignoreCache: false
          }
        }
      )
    ).data;
  },
  async getOrganizer(organizerName, fields) {
    const response = await $api.$get(
      `${this.organizerUrl()}/${organizerName}`,
      {
        params: {
          fields: fields || 'tos'
        }
      }
    );
    return response?.data;
  },
  async getSeatsioSecret(organizerName) {
    const response = await $api.$get(`${this.seatsUrl()}`, {
      params: {
        organizerName
      }
    });
    return response?.data.length > 0 ? response.data[0] : null;
  },
  async saveOrganizer(organizer) {
    const response = organizer.id
      ? await $api.$patch(`${this.organizerUrl()}/${organizer.id}`, organizer)
      : await $api.$post(`${this.organizerUrl()}`, organizer);
    return response?.data;
  },
  async getOrganizerEvent(tenant, organizer, eventId, params) {
    const response = await $api.$get(
      `${this.organizerEventUrl(tenant)}/${organizer}/event/${eventId}`,
      {
        headers: {
          // Cache-Control is set to this combination because it is the only one that fetches actual data in all tested browsers
          'Cache-Control': 'max-age=0, no-cache, no-store, must-revalidate'
        },
        params,
        cache: false
      }
    );
    return response?.data;
  },
  async saveDiscount(tenant, organizer, eventId, discount) {
    const url = `${this.ticketDiscountUrl(tenant, organizer, eventId)}`;
    const uploadDiscount = discount.id
      ? await $api.$patch(`${url}/${discount.id}`, discount)
      : await $api.$post(url, discount);
    return uploadDiscount.data;
  },
  async saveEvent(tenant, organizer, event) {
    const SAVE_EVENT_URL = `${this.organizerEventUrl(
      tenant
    )}/${organizer}/event`;
    const uploadDiscount = event.id
      ? await $api.$patch(`${SAVE_EVENT_URL}/${event.id}`, event)
      : await $api.$post(SAVE_EVENT_URL, event);
    return uploadDiscount.data;
  },
  async saveTicketConfig(tenant, organizer, eventId, ticketConfig) {
    const url = `${this.ticketConfigUrl(tenant, organizer, eventId)}`;
    let response = null;
    if (ticketConfig.id) {
      response = await $api.$patch(`${url}/${ticketConfig.id}`, ticketConfig);
    } else {
      response = await $api.$post(url, ticketConfig);
    }
    if (response.data.style === '') {
      delete response.data.style;
    }
    return response.data;
  },
  async saveTicketFormat(tenant, organizer, eventId, ticketFormat) {
    const SAVE_TICKETFORMAT_URL = `${this.organizerEventUrl(
      tenant
    )}/${organizer}/event/${eventId}`;
    const result = await $api.$patch(`${SAVE_TICKETFORMAT_URL}`, ticketFormat);
    return result.data;
  },
  async deleteEvent(tenant, organizer, eventId) {
    const url = `${this.organizerEventUrl(
      tenant
    )}/${organizer}/event/${eventId}`;
    return await $api.$delete(url);
  },
  async cancelEvent(tenant, organizer, eventId) {
    const url = `${this.organizerEventUrl(
      tenant
    )}/${organizer}/event/${eventId}/cancel`;
    return await $api.$post(url);
  },
  async deleteDiscount(tenant, organizer, eventId, discountId) {
    const url = `${this.ticketDiscountUrl(
      tenant,
      organizer,
      eventId
    )}/${discountId}`;
    return await $api.$delete(url);
  },
  async sendTickets(tenant, ticketsData) {
    return (
      await $api.$post(`${this.ticketUrl(tenant)}/send-group`, ticketsData)
    ).data;
  },
  async getInformationEmail(tenant, organizer, eventId, emailId) {
    return (
      await $api.$get(
        `${this.organizerEventUrl(
          tenant
        )}/${organizer}/event/${eventId}/information_email/${emailId}`
      )
    ).data;
  },
  async saveInformationEmail(tenant, organizer, eventId, emailData) {
    const url = `${this.organizerEventUrl(
      tenant
    )}/${organizer}/event/${eventId}/information_email`;
    return await (
      emailData.id
        ? $api.$patch(`${url}/${emailData.id}`, emailData)
        : $api.$post(url, emailData)
    ).then((res) => res.data);
  },
  async getAllInformationEmails(tenant, organizer, eventId) {
    return (
      await $api.$get(
        `${this.organizerEventUrl(
          tenant
        )}/${organizer}/event/${eventId}/information_email`,
        {
          headers: {
            'Cache-Control': 'no-cache'
          },
          cache: false
        }
      )
    ).data;
  },
  async deleteInformationEmail(tenant, organizer, eventId, emailId) {
    return (
      await $api.$delete(
        `${this.organizerEventUrl(
          tenant
        )}/${organizer}/event/${eventId}/information_email/${emailId}`
      )
    ).data;
  },
  async sendInformationEmail(tenant, organizer, eventId, emailId) {
    return (
      await $api.$post(
        `${this.organizerEventUrl(
          tenant
        )}/${organizer}/event/${eventId}/information_email/${emailId}/send`
      )
    ).data;
  },
  async getTicketConfigs(tenant, organizer, eventId) {
    const response = await $api.get(
      `${this.ticketConfigUrl(tenant, organizer, eventId)}`,
      {
        headers: {
          'Cache-Control': 'no-cache'
        },
        cache: false
      }
    );
    return response.data;
  },
  async updateTicketConfig(
    tenant,
    organizer,
    eventId,
    ticketConfigId,
    questionnaireId,
    kycLevelId = 2
  ) {
    const response = await $api.patch(
      `${this.ticketConfigUrl(tenant, organizer, eventId)}/${ticketConfigId}`,
      { questionnaireId, kycLevelId }
    );
    return response?.data;
  }
});
