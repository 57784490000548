<template>
  <div class="auth-container">
    <div v-if="!resetSent">
      <div class="auth-container__header">
        <h1>{{ $t('main.resetPassword') }}</h1>
        <p>
          {{ $t('main.resetPasswordInfo') }}
        </p>
      </div>
      <div class="auth-container__form">
        <form action="#" onsubmit="return false">
          <BInputField
            v-model="email"
            :label="$t('fields.email') + ' *'"
            type="userEmail"
            @hasError="formError"
          />
          <BButton fill block :disabled="hasError" @click.native="reset()">
            {{ $t('main.reset') }}
          </BButton>
        </form>
        <div class="auth-container__register">
          <p class="auth-container__navigation">
            {{ $t('main.backTo') }}
            <a data-cy="backToLoginLink" @click.stop.prevent="gotoLogin()">{{
              $t('main.login')
            }}</a>
          </p>
        </div>
      </div>
    </div>
    <div v-else class="auth-container__form auth-container__form--center">
      <IconEmail />
      <h2>{{ $t('auth.resetPassword.checkEmail') }}</h2>
      <p>
        {{ $t('auth.resetPassword.steps') }} <b>{{ email }}</b>
      </p>
      <p class="auth-container__navigation">
        {{ $t('main.backTo') }}
        <a @click.stop.prevent="gotoLogin()">{{ $t('main.login') }}</a>
      </p>
    </div>
  </div>
</template>
<script>
import BInputField from '@/components/general/BInputField.vue';
import BButton from '@/components/general/BButton.vue';
import IconEmail from '@/components/general/icons/IconEmail.vue';

export default {
  name: 'BForgotPassword',
  components: {
    BInputField,
    BButton,
    IconEmail
  },
  data() {
    return {
      email: '',
      hasError: true,
      resetSent: false
    };
  },
  methods: {
    async reset() {
      try {
        await this.$services.account.resetPassword({
          email: this.email
        });
        this.resetSent = true;
      } catch (e) {
        this.$notifier.showMessage({
          content: this.$t('auth.resetPassword.error'),
          type: 'error'
        });
      }
    },
    gotoLogin() {
      this.resetSent = false;
      this.$emit('gotoLogin');
    },
    formError(e) {
      this.hasError = e;
    }
  }
};
</script>
