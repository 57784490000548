import { Services } from '../types/services';

// TODO: Add proper types
export const state = () => ({
  validSteps: [],
  user: null,
  userNotFound: false,
  userDeleted: false,
  organizerData: null,
  currencies: [],
  feeType: null,
  feeData: null,
  cancelModal: false
});

export const getters = {
  getSteps: (state) => {
    return state.validSteps;
  },
  getModal: (state) => {
    return state.cancelModal;
  },
  getUser: (state) => {
    return state.user;
  },
  getUserState: (state) => {
    return state.userNotFound;
  },
  getUserDeleted: (state) => {
    return state.userDeleted;
  },
  getOrganizerData: (state) => {
    return state.organizerData;
  },
  getCurrencies: (state) => {
    return state.currencies;
  },
  getFeeType: (state) => {
    return state.feeType;
  },
  getFeeData: (state) => {
    return state.feeData;
  }
};

export const mutations = {
  addStepValid(state, payload) {
    if (state.validSteps.includes(payload)) {
      return;
    }
    state.validSteps.push(payload);
  },
  removeStepValid(state, payload) {
    const index = state.validSteps.indexOf(payload);
    if (index > -1) {
      state.validSteps.splice(index, 1);
    }
  },
  setSteps(state, payload) {
    state.validSteps = payload;
  },
  setModal(state, payload) {
    state.cancelModal = payload;
  },
  setUser(state, payload) {
    state.user = payload;
  },
  setUserNotFound(state, payload) {
    state.userNotFound = payload;
  },
  setUserDeleted(state, payload) {
    state.userDeleted = payload;
  },
  setCurrencies(state, payload) {
    state.currencies = payload;
  },
  setOrganizerData(state, payload) {
    state.organizerData = payload;
  },
  setFeeType(state, payload) {
    state.feeType = payload;
  },
  setFeeData(state, payload) {
    state.feeData = payload;
  }
};

export const actions: any = {
  async searchUser({ commit }, payload) {
    commit('setUserNotFound', false);
    commit('setUserDeleted', false);
    try {
      const response = await (
        this.$services as Services
      ).account.searchUserByEmail(payload);
      commit('setUser', response || false);
    } catch (err) {
      commit('setUserNotFound', true);
    }
  },
  clearUser({ commit }) {
    commit('setUser', null);
    commit('setSteps', []);
  },
  clearUserState({ commit }) {
    commit('setUserNotFound', false);
    commit('setUserDeleted', false);
    commit('setSteps', []);
    commit('setUser', null);
    commit('setModal', false);
    commit('setOrganizerData', null);
    commit('setFeeType', null);
    commit('setFeeData', null);
  },
  async inviteUser({ commit }, payload) {
    try {
      const response = await this.$services.account.inviteUser(payload);
      commit('setUser', response);
      commit('setUserNotFound', false);
      commit('setUserDeleted', false);
    } catch (error) {
      if (error.response.data.statusCode === 409) {
        commit('setUserDeleted', true);
      }
    }
  },
  addStepValid({ commit }, payload) {
    commit('addStepValid', payload);
  },
  removeStepValid({ commit }, payload) {
    commit('removeStepValid', payload);
  },
  setModal({ commit }, payload) {
    commit('setModal', payload);
  },
  setOrganizerData({ commit }, payload) {
    commit('setOrganizerData', payload);
  },
  async saveOrganizerData({ commit, state }, payload) {
    const requestData = { ...payload };
    requestData.ownerId = state.user.id;
    const res = await this.$services.event.saveOrganizer(requestData);
    commit('setOrganizerData', res);
  },
  async getCurrencies({ commit }) {
    const currencies = await this.$services.account.getCurrencies();
    commit('setCurrencies', currencies);
  },
  setFeeType({ commit }, payload) {
    commit('setFeeType', payload);
  },
  setFeeData({ commit }, payload) {
    commit('setFeeData', payload);
  }
};
