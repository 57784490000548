<template>
  <div class="layouts">
    <client-only>
      <BHeader :padding="20" has-max-width @openLogin="openLogin"></BHeader>
    </client-only>
    <div class="layouts__container">
      <nuxt v-if="!$slots.default" @openLogin="openLogin" />
      <slot />
    </div>
    <BFooter v-if="!isBuyScreen" :color="footerColor"></BFooter>
    <BAlert></BAlert>
    <BLoader v-show="getLoading"></BLoader>
    <client-only>
      <transition name="fade">
        <BAuth v-if="getAuthDialog" @close="closeAuthDialog"></BAuth>
      </transition>
    </client-only>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import BHeader from '~/components/general/BHeader';
import BFooter from '~/components/general/BFooter';
import BLoader from '~/components/general/BLoader';
import BAuth from '~/components/general/Auth/BAuth';
import BAlert from '~/components/general/BAlert.vue';

export default {
  name: 'Default',
  components: {
    BHeader,
    BFooter,
    BLoader,
    BAuth,
    BAlert
  },
  data() {
    return {
      themePath: this.$vuetify.theme.themes.light
    };
  },
  computed: {
    ...mapGetters('app', ['getLoading', 'getAuthDialog']),
    isBuyScreen() {
      return this.$route?.name?.includes('tenant-event-id___');
    },
    footerColor() {
      return this.themePath.backgroundPrimary.base;
    }
  },
  methods: {
    closeAuthDialog() {
      this.$store.commit('app/setAuthDialog', false);
      this.$store.commit('app/setEnableGuestLogin', false);
      document.body.style = '';
    },
    openLogin() {
      this.$store.commit('app/setAuthDialog', true);
      document.body.style = 'height: 100vh; overflow-y: hidden';
    }
  }
};
</script>
<style lang="scss" scoped>
.layouts {
  background: var(--v-backgroundGray-lighten2);

  &__container {
    display: block;
    width: 100%;
  }

  &__auth {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.5);

    ::v-deep {
      .login-input {
        margin-bottom: 12px;
      }

      a.forgot-password.info-text {
        display: block;
        width: 100%;
        cursor: pointer;
        text-align: right;
        margin-bottom: 24px;
      }

      .v-tab {
        display: none;
      }

      .info-text {
        font-size: 12px;
        text-align: center;
        padding: 12px 0 0 0;
      }

      .spacer {
        display: none;
      }
    }
  }
}
</style>
