export default ($api, base) => ({
  uploadUrl() {
    return `${base.uploadServiceUrl()}/send`;
  },

  async uploadFile(formData) {
    // set request headers
    const headers = {
      'Content-Type': 'multipart/form-data'
    };
    // upload image
    return await $api.$post(this.uploadUrl(), formData, { headers });
  },
  async uploadProfileImage(formData) {
    // set request headers
    const headers = {
      'Content-Type': 'multipart/form-data'
    };
    // upload image
    return await $api
      .$post(`${base.uploadServiceUrl()}/profile`, formData, {
        headers
      })
      .then((res) => res.data);
  },
  /**
   * Upload a video to api-video service
   * https://develop.bam.fan/upload/documentation/static/index.html#/default/post_v1_video_api_video
   * @param {*} formData
   * @returns
   */
  async uploadVideoToApiVideo(formData) {
    // set request headers
    const headers = {
      'Content-Type': 'multipart/form-data'
    };
    // upload image
    return await $api
      .$post(`${base.uploadServiceUrl()}/video/api-video`, formData, {
        headers
      })
      .then((res) => res.data[0]);
  }
});
