<template>
  <div class="impersonated">
    <div class="MOBILE_h13_Semibold impersonated__user">
      {{ $t('impersonate.impersonatingUser') }} <br />
      <span class="MOBILE_h12_Regular impersonated__user">
        {{ impersonatedUser }}
      </span>
    </div>
    <PButton
      :label="$t('impersonate.endSession')"
      class="p-button-warning impersonated__button"
      @click="unImpersonate()"
    />
  </div>
</template>
<script>
import PButton from 'primevue/button';
export default {
  name: 'BImpersonated',
  components: {
    PButton
  },
  computed: {
    impersonatedUser() {
      const user = this.$store.getters['user/getUser'];
      return user.firstName.length > 0
        ? `${user.firstName} ${user.lastName}`
        : user.email;
    }
  },
  methods: {
    async unImpersonate() {
      await this.$store.dispatch('user/stopImpersonating');
    }
  }
};
</script>
<style lang="scss" scoped>
.impersonated {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &__user {
    color: var(--v-textHighlightWarning-base);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 144px;
  }
  &__content {
    color: var(--v-textPrimary-lighten5);
    font-size: 12px;
    font-weight: bold;
    span {
      font-weight: 400;
      font-size: 14px;
      text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
    }
  }
  &__button {
    color: var(--v-textPrimary-lighten5);
  }
}
</style>
