export default ($api, base) => ({
  async getQuestionnaire(organizerId, tenant) {
    const response = await $api.$get(
      `${base.organizerUrl(
        tenant
      )}/${organizerId}/questionnaire?with=questionnaire_item.question`
    );
    return response.data;
  },
  async getQuestions(organizerId, tenant) {
    const response = await $api.$get(
      `${base.organizerUrl(tenant)}/${organizerId}/question`
    );
    return response.data;
  },
  async addQuestion(organizerId, tenant, question) {
    const response = await $api.$post(
      `${base.organizerUrl(tenant)}/${organizerId}/question`,
      question
    );
    return response.data;
  },
  async saveQuestionnaire(organizerId, tenant, questionnaireData) {
    const response = await $api.$post(
      `${base.organizerUrl(tenant)}/${organizerId}/questionnaire`,
      questionnaireData
    );
    return response.data;
  }
});
