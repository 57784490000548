import { Order } from '@bam/sdk';

export default ($api, base) => ({
  ticketUrl(tenant) {
    return `${base.eventServiceUrl(tenant)}/ticket`;
  },
  orderUrl(tenant) {
    return `${base.eventServiceUrl(tenant)}/order`;
  },
  paymentUrl(tenant) {
    return `${base.paymentServiceUrl(tenant)}/payment`;
  },

  async saveOrderContact(orderId, orderContact, tenant) {
    const response = await $api.$post(
      `${this.orderUrl(tenant)}/${orderId}/order_contact`,
      orderContact
    );
    return response?.data;
  },
  async createPaymentIntent(orderId, tenant) {
    const response = await $api.$post(
      `${this.paymentUrl(tenant)}/stripe?order_id=${orderId}`
    );
    return response.data;
  },
  async createCryptoPayment(orderId, tenant, req) {
    const response = await $api.$post(
      `${this.paymentUrl(tenant)}/coinbase?order_id=${orderId}`,
      req
    );
    return response.data;
  },
  async createHyperpayIntent(orderId, tenant, orderData) {
    const response = await $api.$post(
      `${this.paymentUrl(tenant)}/open-payment?order_id=${orderId}`,
      orderData
    );
    return response.data;
  },
  async getHyperpayStatus(checkoutId, tenant, type) {
    const response = await $api.$get(
      `${this.paymentUrl(tenant)}/open-payment/${checkoutId}?type=${type}`
    );
    return response.data;
  },
  async getBraintreeToken(tenant) {
    const response = await $api.$get(
      `${this.paymentUrl(tenant)}/braintree/token`
    );
    return response.data;
  },
  createBraintreePayment(tenant, req) {
    return $api
      .$post(`${this.paymentUrl(tenant)}/braintree`, req)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return { status: error.response.data.message };
      });
  },
  async removeTicket(order, ticket, tenant) {
    const orderItem = order.orderItem?.filter((item) => {
      return item.ticket?.filter((t) => t.id === ticket.id).length > 0;
    });
    await $api.$delete(
      `${this.orderUrl(tenant)}/${order?.id}/order_item/${
        orderItem?.[0]?.id
      }/ticket/${ticket.id}`
    );
  },
  async createOrder(orderData, tenant) {
    return await $api.$post(this.orderUrl(tenant), orderData);
  },
  async getOrder(orderId, tenant) {
    const response = await $api.$get(`${this.orderUrl(tenant)}/${orderId}`, {
      params: {
        with: '[order_item.ticket.[sector,ticket_config,ticket_discount,ticket_holder],order_contact]'
      }
    });
    return response.data;
  },
  async getOrders(
    eventId: number,
    includeNotOwned: boolean,
    tenant: string
  ): Promise<Order[]> {
    const response = await $api.$get(`${this.orderUrl(tenant)}`, {
      params: {
        eventId,
        includeNotOwned,
        with: '[order_contact]'
      }
    });
    return response.data;
  },
  async searchOrders(
    organizerId: string,
    includeNotOwned: boolean,
    tenant: string,
    id: number,
    contactEmail: string
  ): Promise<Order[]> {
    const response = await $api.$get(`${this.orderUrl(tenant)}`, {
      params: {
        organizerId,
        includeNotOwned,
        id,
        contactEmail,
        with: '[order_contact,order_item.ticket.ticket_config.event]'
      }
    });
    return response.data;
  },
  async getOrderItems(
    eventId: number,
    includeNotOwned: boolean,
    tenant: string,
    orderId: string,
    organizerId = null as string
  ): Promise<Order[]> {
    const response = await $api.$get(`${this.orderUrl(tenant)}`, {
      params: {
        eventId,
        includeNotOwned,
        id: orderId,
        with: '[order_item.ticket.[ticket_config,ticket_discount,sector,ticket_holder.questionnaire.questionnaire_item.question]]',
        organizerId
      }
    });
    return response.data;
  },
  async downloadPdf(orderId, tenant) {
    return await $api.$get(
      `${this.orderUrl(tenant)}/${orderId}/download_tickets`,
      {
        responseType: 'blob'
      }
    );
  },
  async downloadSinglePdf(orderId, ticketId, tenant) {
    return await $api.$get(
      `${this.orderUrl(tenant)}/${orderId}/download_tickets/${ticketId}`,
      {
        responseType: 'blob'
      }
    );
  },
  async resendEmail(orderId, tenant) {
    return await $api.$post(
      `${this.orderUrl(tenant)}/${orderId}/email_tickets`
    );
  },
  async resendEmailAdmin(orderId, tenant) {
    return await $api.$post(
      `${this.orderUrl(tenant)}/${orderId}/admin_email_tickets`
    );
  },
  async refund(orderId, tenant) {
    return await $api.$post(`${this.orderUrl(tenant)}/${orderId}/refund`, {});
  },
  async cancelOrder(orderId, tenant) {
    return await $api.$post(`${this.orderUrl(tenant)}/${orderId}/cancel`, {});
  }
});
