// TODO: Add proper types
export const state = () => ({
  data: null,
  standardQuestions: null
});

export const mutations = {
  setData(state, payload) {
    state.data = payload;
  },
  setStandardQuestions(state, payload) {
    state.standardQuestions = payload;
  },
  setExpanded(state, payload) {
    state.data[payload].expanded = !state.data[payload].expanded;
  }
};

export const actions: any = {
  async fetchQuestionnaire({ commit }, payload) {
    const response = await this.$services.questionnaire.getQuestionnaire(
      payload.organizer,
      payload.tenant
    );
    response.forEach((item) => {
      item.expanded = false;
    });
    commit('setData', response);
  },
  async fetchQuestions({ commit }, payload) {
    const response = await this.$services.questionnaire.getQuestions(
      payload.organizer,
      payload.tenant
    );
    response.forEach((item) => {
      item.mandatory = false;
      item.ask = false;
    });
    commit('setStandardQuestions', response);
  },
  async addQuestion({ state }, payload) {
    const response = await this.$services.questionnaire.addQuestion(
      payload.organizer,
      payload.tenant,
      payload.question
    );
    return response;
  },
  async saveQuestionnaire({ state }, payload) {
    const response = await this.$services.questionnaire.saveQuestionnaire(
      payload.organizer,
      payload.tenant,
      payload.questionnaireData
    );
    return response;
  },
  async getTicketConfigs({ state }, payload) {
    const response = await this.$services.event.getTicketConfigs(
      payload.tenant,
      payload.organizerName,
      payload.id
    );
    return response.data;
  }
};

export const getters = {
  getQuestionnaire(state) {
    return state.data;
  },
  getQuestions(state) {
    return state.questions;
  },
  getStandardQuestions(state) {
    return state.standardQuestions;
  }
};
